<template>
    <v-app class="clean-modal-injury-sof">
        <v-container class="container-modal-injury">
            <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-btn width="0" v-bind="activatorProps" color="blac" text="" variant=""><svg-icon size="25"
                            type="mdi" :path="path"></svg-icon></v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                    <v-toolbar height="24">
                        <v-toolbar-items>

                            <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn>
                        </v-toolbar-items></v-toolbar>
                    <v-card title="Douleur">
                        <section v-if="idPain != undefined && !editMode">
                            <!-- {{ this.getMeasure() }}
                            {{ this.getLocalLocalisation() }} -->
                            <v-slider color="red" readonly  :thumb-size="36"
        thumb-label="always" min="0" max="10" label="Douleur" v-model="this.$data.local_pain.pain.measure">

                            </v-slider>
                             <v-select  :label="this.localisationsForPain.title"   chips multiple :items="[this.localisationsForPain]"></v-select>

                        </section>
                        <section v-else>
                            <v-slider label="Douleur" color="red"  :thumb-size="36"
        thumb-label="always" min="0" max="10" step="1" v-model="this.$data.new_pain.measure">

                            </v-slider>
                            <v-select v-model="this.$data.new_localisation" label="localisation"
                                :items="this.$store.getters.localisations"></v-select>
                        </section>
                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn v-if="editMode" text="ajouter"
                                @click="isActive.value = false; setCurrentPain();"></v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>
//
// Component cycle are build on 
//
//
import SvgIcon from '@jamescoyle/vue-icon';
//import { mdiAccountInjury } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';
import { mdiMedicationOutline } from '@mdi/js';
export default {
    name: 'modale-pain',
    components: {
        SvgIcon
    },
    props: {
        editMode: Boolean,
        idPain: Number,
    },
    
    data() {
        return {
            path: mdiMedicationOutline,
            local_pain:{pain:0}, // in case of read mode
            new_pain: {},// in case of edit/create mode 
            new_localisation:{},//in case of edit/create mode
            localisation: this.$store.getters.localisations, //in case of edit/create mode
        };
    },
    async created() {
        // if (this.$props.idPain) {
        //     this.$store.dispatch("getPain", [this.$props.idPain]);
        // }
        //this.$store.dispatch("getLocalisations");

    },
    async mounted() {
        if (this.$props.idPain) {
            await this.$store.dispatch("getPain", [this.$props.idPain]);
        }
        this.setLocalPain();
    },
    computed: {
        ...mapGetters({ pain: 'current_pain' }),
        ...mapGetters({ localisations: 'localisations' }),
        //...mapGetters({ localisationsForPain: 'localisationsForPain' }),
        localisationsForPain(){
            var id=this.$props.idPain;
            var loca = this.$store.getters.localisationsForPain(id);
            return loca;

        },
        getDatas() {
            var id=this.$props.idPain;
            var hook = this.$store.getters.pains;
            var pain = null;
            var localisation = null
            var result={};
            // search the right pain
            hook.forEach(element => {
                if (element != undefined && element != "empty" && element.idPain == id) {
                    pain =  element;
                }
            });
            // search the localisation 
            hook = this.$store.getters.localisations;
            hook.forEach(element => {
                if (pain && pain.pain_idLocalisation && element != undefined && element != 'empty ' && element.value == pain.pain_idLocalisation)
                    localisation = element;
            });
            // this.$data.local_pain = pain;
            // this.$data.localisation = [localisation];
            result.pain=pain;
            result.localisation=localisation;
            return result; 
        }
 
    },
    methods: {
        ...mapActions(['setActionPain']),
        ...mapActions(['getLocalPain']),
        setCurrentPain() {
            //if (typeof(this.$data.new_pain.pain_idLocalisation) != Number) this.$data.new_pain.pain_idLocalisation = this.$data.new_pain.pain_idLocalisation.value;
            this.$data.new_pain.pain_idLocalisation=this.$data.new_localisation;
            this.setActionPain(this.$data.new_pain);
        },
        setLocalPain(){
            this.$data.local_pain = this.getDatas;
        },
        getMeasure(){
            if(this.$data.local_pain.pain){
                return this.$data.local_pain.pain.measure;
            }
            else{
                return 0;
            }
        },
        getLocalLocalisation(){
            if(this.$data.local_pain.localisation) return [this.$data.local_pain.localisation]

        } 

    }

};
</script>