import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import VueApexCharts from "vue3-apexcharts";
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import VueExcelEditor from 'vue3-excel-editor'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: "mdi",


    },
    date: {
      // adapter:DateFnsAdapter,
    },
  })
const app=createApp(App);
axios.defaults.withCredentials = true;

//axios.defaults.baseURL = 'http://localhost:8000/'; //local dev
//axios.defaults.baseURL = 'https://localhost:8000/'; //prod ... or ??
axios.defaults.baseURL = 'https://gestion-equipes.cepcometti.fr:8080';//for test on infomaniak  fastapi
axios.AxiosHeaders
app.use(router);
app.use(store);
app.use(vuetify);
app.use(VueApexCharts);
app.use(VueExcelEditor);
app.mount("#app");