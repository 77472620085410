<template>
  <div>
   <span v-if="msg != ''" ><h6 class="infos">Le serveur FASTAPI est accessible </h6><small>voir la  <a href="#" @click="openDoc()">documentation </a></small></span>
   <span v-else><h6  class="error_flag">Le serveur fast api est inaccessible</h6> contactez svp le support</span>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Ping-hello',
  data() {
    return {
      msg: '',
    };
  },
  methods: {
    getMessage() {
      axios.get('/')
        .then((res) => {
          this.msg = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openDoc(){
      window.open(this.msg.message, '_blank');
    }
  },
  created() {
    this.getMessage();
  },
};
</script>
