<template>
    <div> <!-- modale evaluation tests -->
        <section>
            <v-dialog max-width="auto" max-height="auto" scrollable>
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn class="custom-padding10" v-bind="activatorProps"
                                @click="clean_current_store"><svg-icon type="mdi" :path="path"></svg-icon></v-btn>
                        </template>
                        <template v-slot:default="{ isActive }">
                            <v-toolbar height="24">
                                <v-toolbar-items>

                                    <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn>
                                </v-toolbar-items></v-toolbar>
                            <v-card title="Ajout Évaluation medicale">
                                <v-form class="form-evaluation" @submit.prevent="submit">
                                    <section v-if="idStateOfForm != undefined && !editMode">
                                    </section>
                                    <section v-else>

                                        <v-text-field class="w-50 ml-4" label="taille en cm" id="taille"
                                            v-model="new_stateOfForm.height">
                                        </v-text-field>
                                        <v-text-field class="w-50 ml-4" label="poids en kg" id="poids"
                                           @keyup.enter="updateMass" v-model="new_stateOfForm.weight">
                                        </v-text-field>
                                        <v-text-field class="w-50 ml-4" label="commentaire" v-model="new_stateOfForm.comment">

                                        </v-text-field>
                                        <!-- <v-text-field class="w-50 ml-4" label="masse graisseuse en kg" id="_graisse"
                                        @keyup.enter="updateMass" v-model="local_fatmass">
                                        </v-text-field> -->
                                        <div class="text-caption ma-2 m-2">
                                            Masse graisseuse (indice entre 0 et 1):
                                        </div>
                                        <v-slider class="ma-6" width="80%" :min="0" :max="1" :step="0.01"
                                            track-color="blue" color="#0000EE" label="" id="fatmass"
                                            v-model="new_stateOfForm.fatmass">
                                            <template v-slot:append>
                                                <v-text-field v-model="new_stateOfForm.fatmass" density="compact"
                                                    style="width: 80px" type="number" variant="outlined" step="0.01"
                                                    hide-details></v-text-field>
                                            </template>
                                        </v-slider>

                                        <!-- <v-text-field class="w-50 ml-4" label="masse musculaire en kg" id="_muscle"
                                            onkeyup="this.updateMass" v-model="local_musclemass">
                                        </v-text-field> -->
                                        <div class="text-caption ma-2 m-2">
                                            Masse musculaire (indice entre 0 et 1):
                                        </div>
                                        <v-slider class="ma-6" width="80%" :min="0" :max="1" :step="0.01"
                                            track-color="blue" color="#0000EE" label="" id="musclemass"
                                            v-model="new_stateOfForm.musclemass">
                                            <template v-slot:append>
                                                <v-text-field v-model="new_stateOfForm.musclemass" density="compact"
                                                    style="width: 80px" type="number" variant="outlined"
                                                    hide-details step="0.01"></v-text-field>
                                            </template>
                                        </v-slider>
                                        <div class="text-caption ma-2 m-2">
                                            Masse hydrique (indice entre 0 et 1):
                                        </div>
                                        <v-slider class="ma-6" width="80%" :min="0" :max="1" :step="0.01"
                                            track-color="blue" color="#0000EE" label="" id="water"
                                            v-model="new_stateOfForm.water">
                                            <template v-slot:append>
                                                <v-text-field v-model="new_stateOfForm.water" density="compact"
                                                    style="width: 80px" type="number" variant="outlined"
                                                    hide-details step="0.01"></v-text-field>
                                            </template>
                                        </v-slider>
                                        <div class="text-caption ma-2 m-2">
                                            Rythme cardiaque au repos:
                                        </div>
                                        <v-slider class="ma-6" width="80%" :min="10" :max="250" :step="0.01"
                                            track-color="blue" color="#0000EE" label="" id="hr_rest"
                                            v-model="new_stateOfForm.hr_rest">
                                            <template v-slot:append>
                                                <v-text-field v-model="new_stateOfForm.hr_rest" density="compact"
                                                    style="width: 80px" type="number" variant="outlined"
                                                    hide-details></v-text-field>
                                            </template>
                                        </v-slider>
                                        <div class="text-caption ma-2 m-2">
                                            Rythme cardiaque max:
                                        </div>
                                        <v-slider class="ma-6" width="80%" :min="10" :max="220" :step="1"
                                            track-color="blue" color="#0000EE" label="" id="hr_max"
                                            v-model="new_stateOfForm.hr_max">
                                            <template v-slot:append>
                                                <v-text-field v-model="new_stateOfForm.hr_max" density="compact"
                                                    style="width: 80px" type="number" variant="outlined"
                                                    hide-details></v-text-field>
                                            </template>
                                        </v-slider>
                                        <div class="text-caption ma-2 m-2">
                                            Vitesse maximale aérobie (VMA en km/h):
                                        </div>
                                        <v-slider class="ma-6" width="80%" :min="1" :max="60" :step="1"
                                            track-color="blue" color="#0000EE" label="" id="vma"
                                            v-model="new_stateOfForm.vma">
                                            <template v-slot:append>
                                                <v-text-field v-model="new_stateOfForm.vma" density="compact"
                                                    style="width: 80px" type="number" variant="outlined"
                                                    hide-details></v-text-field>
                                            </template>
                                        </v-slider>
                                        <div class="text-caption ma-2 m-2">
                                            Consommation maximale d'oxygène (Vo2 max mesurée en ml/minute):
                                        </div>
                                        <v-slider class="ma-6" width="80%" :min="1000" :max="6000" :step="10"
                                            track-color="blue" color="#0000EE" label="" id="vo2"
                                            v-model="new_stateOfForm.vo2max">
                                            <template v-slot:append>
                                                <v-text-field v-model="new_stateOfForm.vo2max" density="compact"
                                                    style="width: 80px" type="number" variant="outlined"
                                                    hide-details></v-text-field>
                                            </template>
                                        </v-slider>
                                        <v-date-picker label="date" id="date" v-model="new_date">
                                        </v-date-picker>
                                    </section>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>

                                        <v-btn type="submit" v-if="!editMode" text="ajouter"
                                            @click="isActive.value = false;"></v-btn>
                                        <v-btn  v-if="editMode" text="modifier"
                                            @click="isActive.value = false;updateEvaluation()"></v-btn>
                                        
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </template>
                    </v-dialog>

        </section>
    </div>
</template>

<script>
//
// Component cycle are build on 
//
//
import SvgIcon from '@jamescoyle/vue-icon';
//import { mdiAccountInjury } from '@mdi/js';
//import { mapActions, mapGetters } from 'vuex';
import { mdiHeartPulse } from '@mdi/js';
import { mapActions } from 'vuex';
export default {
    name: 'modale-evaluations-test',
    components: {
        SvgIcon
    },
    props: {
        editMode: Boolean,
        idStateOfForm: Number,

    },

    data() {
        return {
            new_stateOfForm: {
                isDaily: 0,
            },
            new_date: new Date(),
            local_musclemass: -1,
            local_fatmass: -1,
            path: mdiHeartPulse,
        };
    },
    async created() {


    },
    async mounted() {

    },
    async updated(){
        this.$store.dispatch("getStatesOfFormForAthl");
    },
    computed: {


    },
    methods: {
        updateMass() {
            alert(this.$data.new_stateOfForm.weight);
            if (this.$data.new_stateOfForm.weight != undefined && this.$data.new_stateOfForm.weight != 'empty') {
                //update fat mass if we have a value
                this.$data.new_stateOfForm.fatmass = (this.$data.local_fatmass > 0) ? Math.round((this.$data.new_stateOfForm.weight / this.$data.local_fatmass) * 100) / 100 : this.$data.new_stateOfForm.fatmass;
                //update musle mass if we nhave a value
                this.$data.new_stateOfForm.musclemass = (this.$data.local_fatmass > 0) ? Math.round((this.$data.new_stateOfForm.weight / this.$data.local_musclemass) * 100) / 100 : this.$data.new_stateOfForm.musclemass;
            }
        },
        ...mapActions(["createStateOfForm","setCurrentStateOfForm"]),    
        async submit() {
            this.new_stateOfForm.isDaily=0;
            this.new_stateOfForm.evalDate = this.$data.new_date.toISOString();
            this.new_stateOfForm.eval_idAthlete = this.$store.getters.getProfile.idAthlete;

            // 2     
            // add the date
            this.new_stateOfForm.date = this.$data.new_date.toISOString();
            await this.setCurrentStateOfForm(this.new_stateOfForm);
            await this.$store.dispatch("createStateOfForm");
            this.$store.dispatch("getStatesOfFormForAthl");
            
        },
        updateEvaluation(){
            alert("update");
        }

    },

};
</script>