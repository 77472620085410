<template>
    <div> <!-- modale evaluations quotidienne -->
        <section>
            <v-dialog width="500">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-btn v-bind="activatorProps" @click="clean_current_store"><svg-icon type="mdi"
                            :path="path"></svg-icon></v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                    <v-toolbar height="24">
                        <v-toolbar-items>

                            <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn>
                        </v-toolbar-items></v-toolbar>

                    <v-card title="ajout d'une évaluation quotidienne">
                        <v-form class="form-evaluation" @submit.prevent="submit">


                            <v-text-field class="w-50 ml-4" label="taille en cm" id="taille" v-model="form.height">
                            </v-text-field>
                            <v-text-field class="w-50 ml-4" label="poids en kg" id="poids" v-model="form.weight">
                            </v-text-field>
                            <v-text-field class="w-50 ml-4" label="informations" id="comment" v-model="form.comment">
                            </v-text-field>
                            <div class="text-caption ma-2 m-2">
                                Forme physique (niveau de fatigue de 0 à 10) :
                            </div>
                            <v-slider class="ma-6" width="80%" :min="0" :max="10" :step="1" track-color="blue"
                                color="#0000EE" label="" id="fatigue" v-model="form.fatigue">
                                <template v-slot:append>
                                    <v-text-field v-model="form.fatigue" density="compact" style="width: 80px"
                                        type="number" variant="outlined" hide-details></v-text-field>
                                </template>
                            </v-slider>
                            <div class="text-caption ma-2">
                                Qualité de sommeil :
                            </div>
                            <v-slider class="ma-6" width="80%" :min="0" :max="10" :step="1" track-color="blue"
                                color="#0000EE" label="" id="sleep" v-model="form.sleep">
                                <template v-slot:append>
                                    <v-text-field v-model="form.sleep" density="compact" style="width: 80px"
                                        type="number" variant="outlined" hide-details></v-text-field>
                                </template>
                            </v-slider>
                            <div class="text-caption ma-2">
                                Stress :
                            </div>
                            <v-slider class="ma-6" width="80%" :min="0" :max="10" :step="1" track-color="blue"
                                color="#0000EE" label="" id="stress" v-model="form.stress">
                                <template v-slot:append>
                                    <v-text-field v-model="form.stress" density="compact" style="width: 80px"
                                        type="number" variant="outlined" hide-details></v-text-field>
                                </template>
                            </v-slider>
                            <div class="text-caption ma-2">
                                Motivation :
                            </div>
                            <v-slider class="ma-6" width="80%" :min="0" :max="10" :step="1" track-color="blue"
                                color="#0000EE" label="" id="tailmotivationle" v-model="form.motivation">
                                <template v-slot:append>
                                    <v-text-field v-model="form.motivation" density="compact" style="width: 80px"
                                        type="number" variant="outlined" hide-details></v-text-field>
                                </template>
                            </v-slider>
                            <div class="text-caption ma-2">
                                Émotions (sentiment de bien être de 0 à 10) :
                            </div>
                            <v-slider class="ma-6" width="80%" :min="0" :max="10" :step="1" track-color="blue"
                                color="#0000EE" label="" id="humeur" v-model="form.mood">
                                <template v-slot:append>
                                    <v-text-field v-model="form.mood" density="compact" style="width: 80px"
                                        type="number" variant="outlined" hide-details></v-text-field>
                                </template>
                            </v-slider>
                            <div class="text-caption ma-2">
                                Niveau de difficulté entrainement :
                            </div>
                            <v-slider class="ma-6" width="80%" :min="0" :max="10" :step="1" track-color="blue"
                                color="#0000EE" label="" id="rpe" v-model="form.RPE_WEEK">
                                <template v-slot:append>
                                    <v-text-field v-model="form.RPE_WEEK" density="compact" style="width: 80px"
                                        type="number" variant="outlined" hide-details></v-text-field>
                                </template>
                            </v-slider>
                            <div class="d-inline-flex modal-sof-mmodales">
                                <modaleInjury :edit-mode="true" :date="new_date" />
                                <modalePathology :edit-mode="true" :date="new_date" />
                                <modalePain :edit-mode="true" />
                            </div>


                            <v-date-picker label="date" id="date" v-model="new_date">
                            </v-date-picker>



                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn type="submit" text="Ajouter" variant="flat"
                                    @click="isActive.value = false;"></v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </template>
            </v-dialog>

        </section>
    </div>
</template>
<script>
//  import athletes from '@/store/modules/athletes';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import '@/css/forms.css';
import SvgIcon from '@jamescoyle/vue-icon';
//import { mdiHeartPulse } from '@mdi/js';
import { mdiWeightLifter } from '@mdi/js';
//import AthleteForms from '@/components/AthleteForms.vue';
import modaleInjury from '@/components/modaleInjury.vue';
import modalePathology from '@/components/modalePathology.vue';
import modalePain from '@/components/modalePain.vue';
//import modaleEvaluation from '@/components/modaleEvaluation.vue';
export default defineComponent({
    name: 'modale-EvalutionQuo',
    components: {
        SvgIcon,
        modaleInjury,
        modalePathology,
        modalePain,

    },
    props: {
        editMode: Boolean
    },
    data() {
        return {
            new_date: new Date(),
            form: {
                taille: 0,
            },
            rules_t: [
                value => value.isNull || value.isInteger
            ],
            path: mdiWeightLifter,





        };
    },
    created: function () {

    },
    async updated(){
        this.$store.dispatch("getStatesOfFormForAthl");
    },
    computed: {


    },
    methods: {
        ...mapActions(["setCurrentStateOfForm"]),
        async submit() {
            // submit depuis le store 'suivis'
            // 1 ajout des données pour la table evaluation (dernier enregistrement)
            // stateOfForm  et evaluation fk -> injury-> pathology 

            this.form.evalDate = this.$data.new_date.toISOString();
            this.form.eval_idAthlete = this.$store.getters.getProfile.idAthlete;

            // 2     
            // add the date
            this.form.date = this.$data.new_date.toISOString();
            await this.setCurrentStateOfForm(this.form);
            await this.$store.dispatch("createStateOfForm");
            this.$store.dispatch("getStatesOfFormForAthl");

        },
        clean_current_store() {
            // clean_current_store :
            //      clean vuex current_*** state to avoid unwanted procesing
            // 

            this.$store.dispatch("clean_current_suivi");
        },
    },
});
</script>