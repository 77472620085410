<template>
  <v-app class="clean-modal-injury-sof">
    <v-container class="container-modal-injury">
      <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn width="0" v-bind="activatorProps" color="" text="" variant=""><svg-icon size="25" type="mdi"
              :path="path"></svg-icon></v-btn>
        </template>

        <template v-slot:default="{ isActive }">
          <v-toolbar height="24">
            <v-toolbar-items>

              <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn>
            </v-toolbar-items></v-toolbar>
          <v-card title="Blessure">
            <section v-if="idInjury != undefined && !editMode"> 

              <v-text-field type="hidden" v-model="this.getInjuryByid.idInjury">

              </v-text-field>
              <v-select :label="this.localisationsForInjury.title" chips readonly  v-if="this.getInjuryByid.injury_idLocalisation" :items="[this.localisationsForInjury]">

              </v-select>

              <v-text-field v-model="this.getInjuryByid.injury_comment">

              </v-text-field>

              <v-text-field v-model="this.getInjuryByid.externalIdentifier">

              </v-text-field>
              <v-text-field v-model="this.getInjuryByid.date">

              </v-text-field>
            </section>
            <section v-else>
              <v-select v-model="this.$data.localisation" label="localisation"
                :items="this.$store.getters.localisations"></v-select>

              <v-text-field label="comment" v-model="this.$data.new_injury.injury_comment">

              </v-text-field>

              <v-text-field label="Lien externe " v-model="this.$data.new_injury.externalIdentifier">

              </v-text-field>
              <v-text-field readonly v-model="this.$props.date">

              </v-text-field>


            </section>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn v-if="editMode" text="ajouter" @click="isActive.value = false; setCurrentInjury();"></v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiAccountInjury } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';
// import { mdiMedicationOutline } from '@mdi/js';
export default {
  name: 'modale-injury',
  components: {
    SvgIcon
  },
  props: {
    editMode: Boolean,
    idInjury: Number,
    date: Date
  },
  data() {
    return {
      msg: '',
      path: mdiAccountInjury,
      date_injury: new Date(),
      new_injury: {
        date: new Date(),
      },
      localisation:{title:"",value:null},
    };
  },
  async created() {
     await this.$store.dispatch("getInjuryByIds", [this.$props.idInjury]);
     await this.$store.dispatch("getLocalisations");
  },
  async mounted(){
    await this.$store.dispatch("getInjuryByIds", [this.$props.idInjury]);
    await this.$store.dispatch("getLocalisations");
    
  },
  computed: {
    ...mapGetters({ injury: 'current_injury' }),
    ...mapGetters({ localisations: 'localisations' }),
    localisationsForInjury(){
        var id=this.$props.idInjury;
        return  this.$store.getters.localisationsForInjury(id);
    },  
    getInjuryByid(){
      var element= {...this.$store.getters.injury_by_id(this.$props.idInjury)};
      var date=new Date(element.date).toLocaleDateString('fr');
      element.date=date;
      return element;
    }
  },
  methods: {
    ...mapActions(['getInjuryByIds','setInjury']),
    setInjuryDate() {
      // this.$data.date_injury =this.injury.date;

    },
    setCurrentInjury() {
      if(typeof(this.$data.new_injury.date) == Date) this.$data.new_injury.date = this.$data.new_injury.date.toISOString();
      this.$data.new_injury.injury_idLocalisation=this.$data.localisation;
      // stored in the vuex store
      this.setInjury(this.$data.new_injury);
    }

  }

};
</script>
