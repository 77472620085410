<template>
    <div>
        <section>

        </section>
    </div>

</template>
<script>
import { defineComponent } from 'vue';
// import { mapGetters, mapActions } from 'vuex';
// import DataTable from 'datatables.net-vue3';
// import DataTablesCore from 'datatables.net';
// import '@/css/forms.css';
// import SvgIcon from '@jamescoyle/vue-icon';
// import { mdiHumanEdit } from '@mdi/js';
// import { mdiAccountPlusOutline } from '@mdi/js';
// import { mdiDeleteForever } from '@mdi/js';
// import { mdiHeartPulse } from '@mdi/js';
// DataTable.use(DataTablesCore);
export default defineComponent({
    name:"dashboard-trainer",


});


</script>