<template>
    <v-app class="clean-modal-injury-sof">
        <v-container class="container-modal-injury">
            <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-btn width="0" v-bind="activatorProps" color="blac" text="" variant=""><svg-icon size="25"
                            type="mdi" :path="path"></svg-icon></v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                    <v-toolbar height="24">
                        <v-toolbar-items>

                            <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn>
                        </v-toolbar-items></v-toolbar>
                    <v-card title="Pathologie">
                        <section v-if="idPathology != undefined && !editMode">
                            <v-text-field label="nom de la pathologie" v-model="this.$data.local_pathology.pathologyName">

                            </v-text-field>
                            
                            <v-text-field label="description" v-model="this.$data.local_pathology.description">

                            </v-text-field>
                        </section>
                        <section v-else>
                            <v-text-field label="nom de la pathologie" v-model="this.$data.new_pathology.pathologyName">

                            </v-text-field>
                            
                            <v-textarea label="description" v-model="this.$data.new_pathology.description">

                            </v-textarea>
                        </section>
                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn v-if="editMode" text="ajouter" @click="isActive.value = false; setCurrentPathology();"></v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>

import SvgIcon from '@jamescoyle/vue-icon';
//import { mdiAccountInjury } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';
//import { mdiMedicationOutline } from '@mdi/js';
import { mdiEmoticonSickOutline } from '@mdi/js';
export default {
    name: 'modale-pathology',
    components: {
        SvgIcon
    },
    props: {
        editMode: Boolean,
        idPathology: Number,
    },
    data() {
        return {
            path: mdiEmoticonSickOutline,
            new_pathology: {},
            local_pathology:{},
        };
    },
    created() {
        // if(this.$props.idPathology){
        //     this.$store.dispatch("getPathology",[this.$props.idPathology]);
        // }

    },
    async mounted(){
        if(this.$props.idPathology){
            await this.$store.dispatch("getPathology",[this.$props.idPathology]);
        }
        this.setLocalPathology();
    },
    computed: {
        ...mapGetters({pathology:'current_pathology'}),
        getDatas(){
            var id=this.$props.idPathology;
            var hook=this.$store.getters.pathologies;
            var pathology=null;
            hook.forEach(element => {
                if (element != undefined && element != "empty" && element.idPathology == id) {
                    pathology =  element;
                }
            });
            return pathology;
        }

    },
    methods: {
        ...mapActions(['setActionPathology']),
        setCurrentPathology() {
            this.setActionPathology(this.$data.new_pathology)
        },
        setLocalPathology(){
            this.$data.local_pathology=this.getDatas;
        }

    }

};
</script>