<template>
  <div class="home">
    <img alt="Vue logo" width="80%" src="../assets/logo-cep.jpg">
    <HelloWorld msg="fast api not found"/>
   
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'HomeView',
  components: {
    HelloWorld
  }
});
</script>
