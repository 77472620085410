<template>
  <!--  -->
    <v-card class="mx-auto left" max-width="500">
      <v-container fluid>
        <v-row dense><v-cols cols="12"><v-card-title class="text-h5"><v-card-item>{{ athlete.athleteName }}</v-card-item></v-card-title>
          <v-card-subtitle class="text-h6"><v-car-item> {{ athlete.athleteSurname }}</v-car-item></v-card-subtitle></v-cols> </v-row>
        <v-row dense><v-card-text>{{ athlete.sex }}</v-card-text><v-card-text> {{ athlete.birthdate }}</v-card-text></v-row>
        <v-row dense>
          <v-cols cols="12">
          <v-card-item v-if="athlete.lat_hand == 1"> main : droitier </v-card-item>
          <v-card-item v-if="athlete.lat_hand == 0">main : gaucher </v-card-item>
          <v-card-item v-if="athlete.lat_hand == 2"> main : les deux </v-card-item>
          <v-card-item v-if="athlete.lat_foot == 1"> pied : droitier </v-card-item>
          <v-card-item v-if="athlete.lat_foot == 0">pied : gaucher </v-card-item>
          <v-card-item v-if="athlete.lat_foot == 2">pied : les deux </v-card-item>
          </v-cols>
          <v-cols cols="12">
            <v-card-item v-for="compo in info_compo" :key="compo.composition_idAthlete">
              <v-card-title><v-card-item>{{ compo.seasonStart }}</v-card-item></v-card-title>
              
              <v-card-subtitle class="text-h7">
                <v-card-item v-if="compo.seasonEnd">{{ compo.seasonEnd }}</v-card-item>   
              </v-card-subtitle>
              <v-card-text>
                <v-row dense >
                  <v-card-item>position :{{compo.position}}</v-card-item>
                  <v-card-item>vol entraînement :{{compo.vol_train}}</v-card-item>
                  <v-card-item>vol spécialité :{{compo.vol_spe}}</v-card-item>
                  <v-card-item>expérience spécialité :{{compo.exp_spe}}</v-card-item>
                </v-row>
                
              </v-card-text>
            </v-card-item>
          </v-cols>
          <v-cols cols="12">
            <v-card-actions v-for="t in athletes_suivi_teams" :key="t.idTeam">
              <v-btn @click="fn(t.idTeams)">{{t.teamName}}</v-btn>
            
            </v-card-actions>
        
          </v-cols>
          
        </v-row>
       <v-card-actions v-for="compo in athlete_suivi_compo" :key="compo.composition_idAthlete">
            <v-btn
            color="deep-purple-accent-4"
            text="Learn More"
            variant="text"
          ></v-btn>
      </v-card-actions>
        
      </v-container>
      
    </v-card>
    <div>
        <!-- <form @submit.prevent="submit">
            
        </form> -->
    </div>
  </template>
<script>
//import athletes from '@/store/modules/athletes';
import { defineComponent } from 'vue';
import { mapGetters,mapActions  } from 'vuex';
 export default defineComponent({
    name: 'Suivi-athlete-cep',
    data() {
      return {
        form: {
        
        },
        // athlete:this.getSelectedAthlete(),
        
      };
    },
    created: function() {
      return this.$store.dispatch('getComposition');
    },
    computed: {
      //form model
      ...mapGetters({new_etet_deforme:'new_etat_de_forme'}),
      ...mapGetters({new_evaluation:'new_evaluation'}),
      ...mapGetters({new_injury:'new_injury'}),
      ...mapGetters({new_pathology:'new_pathology'}),

      //main information model

      ...mapGetters({athletes_suivi_teams:'athletes_suivi_teams'}),
      ...mapGetters({main_info:'last_eval'}),
      ...mapGetters({athlete:'selected'}),
      //history model
      ...mapGetters({info_compo:'athlete_suivi_compo'}),
      ...mapGetters({evaluations:'evaluations'}),
      ...mapGetters({injuries:'injuries'}),
      ...mapGetters({pathologies:'pathologies'}),
      
      //selection 
      ...mapGetters({selected_evaluation:'selected_evaluation'}),
      ...mapGetters({selected_etat_de_forme:'selected_etat_de_forme'}),
      ...mapGetters({selected_eval:'selected_eval'}),
    },
    methods: {
      ...mapActions(['getSelectedAthlete']),
      selectAthlete(){
        return this.getSelectedAthlete();
      }
    },
  });
</script>
  