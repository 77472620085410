<template>
    <header>
      <nav class="navbar navbar-expand-md navbar-dark bg-dark">
        <div class="container">
          <a class="navbar-brand" href="/">Gestion Equipes CEP</a>
          <button @click="collapse()" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
            </span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul v-if="!isExpire()" class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                <router-link class="nav-link" to="/">Accueil</router-link>
              </li>
              <li class="nav-item">
                <router-link v-if="isAdmin()" class="nav-link" to="//admin'">Tableau de bord administration</router-link>
              </li>
              <li class="nav-item">
                <router-link v-if="isEntrain()" class="nav-link" to="/trainer">Tableau de bord Entraînement</router-link>
              </li>
              <li class="nav-item">
                <router-link v-if="isAthlete()" class="nav-link" to="/trainee">Tableau de bord athlète</router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link class="nav-link" to="/profile">Mon Profile</router-link>
              </li> -->
              <li class="nav-item">
                <a class="nav-link" @click="logout">deconnexion</a>
              </li>
            </ul>
            <ul v-else class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                <router-link class="nav-link" to="/">Accueil</router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link class="nav-link" to="/register">S'enregistrer</router-link>
              </li> -->
              <li class="nav-item">
                <router-link class="nav-link" to="/login">Log In</router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

  export default defineComponent({
    name: 'NavBar',
    data(){
        return{
            isCollapsed:false,
        }
    },
    updated:function(){
      
        this.$store.dispatch("verifyToken");
        if(this.$store.getters.isTokenExpire){
          this.$router.push("/login");
        }
      
    },
    computed: {
       ...mapGetters({isTokenExpire:"isTokenExpire"}),
    },
    methods: {
      collapse(){
        if(this.$data.isCollapsed){
            document.getElementById("navbarCollapse").style.display="none";
            this.$data.isCollapsed=false;
        }
        else{
            document.getElementById("navbarCollapse").style.display="block";
            this.$data.isCollapsed=true;
        }
      },
      async logout () {
        await this.$store.dispatch('logOut');
        this.$router.push('/login');
      },
      isExpire (){
        this.$store.dispatch("verifyToken");
        return this.$store.getters.isTokenExpire;
        
      },
      isAthlete(){
        return this.$store.getters.isAthlete;
      },
      isEntrain(){
        return this.$store.getters.isEntrain;
      },
      isAdmin(){
        return this.$store.getters.isAdmin;
      }
    
 
    },
  });
  </script>
  
  <style scoped>
  a {
    cursor: pointer;
  }
  </style>
  