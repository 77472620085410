<template>
    <section>
      <form @submit.prevent="submit">
        <div class="mb-3">
          <label for="username" class="form-label">Username:</label>
          <input type="text" name="username" v-model="form.username" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password:</label>
          <input type="password" name="password" v-model="form.password" class="form-control" />
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </section>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { mapActions } from 'vuex';
  
  export default defineComponent({
    name: 'Login-cep',
    data() {
      return {
        form: {
          username: '',
          password:'',
        }
      };
    },
    methods: {
      ...mapActions(['logIn']),
      async submit() {
        const User = new FormData();
        User.append('username', this.form.username);
        User.append('password', this.form.password);
        await this.logIn(User);
        if(this.$store.getters.isAthlete){
          this.$router.push('/trainee');
        }
        else if(this.$store.getters.isAdmin){
          this.$router.push('/admin');
        }
        else if(this.$store.getters.isEntrain){
          this.$router.push('/trainer');
        }
        else{
          this.$router.push('/dashboard');
        }
        
      }
    }
  });
  </script>
  