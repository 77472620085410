<template>
  <div> <!-- partie titre et toolbox -->
    <section>

          <v-dialog max-width="auto" max-height="auto" scrollable>
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn v-bind="activatorProps" color="gray" text=""> <svg-icon type="mdi"
                  :path="path_add"></svg-icon></v-btn>
            </template>

            <template v-slot:default="{ isActive }">
              <v-form class="" @submit.prevent="submit">
                <v-card>
                  <v-row dense>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field label="nom" name="name" v-model="new_athlete.athleteName" />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field label="prenom" name="surename" v-model="new_athlete.athleteSurname" />
                    </v-col>
                  </v-row>
                  <v-row dense>

                    <v-col cols="12" md="4">
                      <v-select name="main" v-model="new_athlete.lat_hand"
                        :items="[{ title: 'main droite', value: 1 }, { title: 'main gauche', value: 0 },{ title: 'les deux', value: 2 }]" label="" />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-select name="valeur main" v-model="new_athlete.lat_hand" :items="[0, 1,2]" label="valeur"
                        readonly />
                    </v-col>
                  </v-row>
                  <v-row dense>

                    <v-col cols="12" md="4">
                      <v-select name="pied" v-model="new_athlete.lat_foot"
                        :items="[{ title: 'pied droit', value: 1 }, { title: 'pied gauche', value: 0 },{ title: 'les deux', value: 2 }]" label="" />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-select name="valeur pied" v-model="new_athlete.lat_foot" :items="[0, 1,2]" label="valeur"
                        readonly />
                    </v-col>
                  </v-row>
                  <v-row dense>

                    <v-col cols="12" md="4">
                      <v-select label="genre" name="genre" v-model="new_athlete.sex" :items="['M', 'F']" />
                    </v-col>
                  </v-row>
                  <v-row> <v-col cols="6" md="4">

                      <v-date-picker id="date_athlete" label="date" title="Date de naissance"
                        :max-date="Date.parse('2010-01-01')" name="date" width="400" color="primary"
                        v-model="new_date" />

                    </v-col>
                  </v-row>
                  <v-btn type="submit" class="btn btn-primary" @click="isActive.value = false">créer</v-btn>
                </v-card>
              </v-form>
            </template>
          </v-dialog>
    </section>
    <!-- affichage de la partie principale -->
    <!-- <v-data-table :items="athletes" ></v-data-table> -->

    <section>
      <legend><small>Athletes</small></legend>
      <v-form class="md5" @submit.prevent="search">
        <v-row class="h-50" height="20" dense>
          <v-col cols="12" md="1">
            <v-text-field class="" type="text" name="search" v-model="form_search.needle"> </v-text-field>
          </v-col>
          <v-col align-sef="start" cols="12" md="1">
            <v-btn type="submit" icon="mdi-magnify" color="" size="0.5"></v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-table v-if="athletes.length" class="table" height="300px" fixed-header>

        <thead>
          <tr>
            <th>id</th>
            <th>nom</th>
            <th>prenom</th>
            <th>date de naissance</th>
            <th>main </th>
            <th>pied </th>
            <th>genre</th>
            <th></th>
          </tr>
        </thead>
        <tr v-for="athlete in athletes" :key="athlete.idAthlete" class="athlete">


          <td> {{ athlete.idAthlete }}</td>
          <td> {{ athlete.athleteName }}</td>
          <td> {{ athlete.athleteSurname }}</td>
          <td> {{ athlete.birthdate }}</td>
          <td v-if="athlete.lat_hand == 1"> droitier </td>
          <td v-if="athlete.lat_hand == 0"> gaucher </td>
          <td v-if="athlete.lat_hand == 2"> les deux </td>
          <td v-if="athlete.lat_foot == 1"> droitier </td>
          <td v-if="athlete.lat_foot == 0"> gaucher </td>
          <td v-if="athlete.lat_foot == 2"> les deux </td>
          <td> {{ athlete.sex }}</td>
          <td><v-dialog max-width="auto" max-height="auto" scrollable>
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn v-bind="activatorProps" color="white" size="small" text="" @click="select(athlete.idAthlete)">
                  <svg-icon type="mdi" height="20px" width="20px" :path="path_edit"></svg-icon></v-btn>
              </template>

              <template v-slot:default="{ isActive }">
                <v-form class="" @submit.prevent="update">
                  <v-card>



                    <v-toolbar height="24">
                      <v-toolbar-items>

                        <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn> </v-toolbar-items></v-toolbar>

                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="id" name="idAthlete" v-model="athlete.idAthlete" readonly />
                        <v-text-field label="nom" name="name" v-model="athlete.athleteName" />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field label="prenom" name="surename" v-model="athlete.athleteSurname" />
                      </v-col>
                    </v-row>
                    <v-row dense>

                      <v-col cols="12" md="4">
                        <v-select name="main" v-model="athlete.lat_hand"
                          :items="[{ title: 'main droite', value: 1 }, { title: 'main gauche', value: 0 }, { title: 'les deux', value: 2 }]" label="" />
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-select name="valeur main" v-model="athlete.lat_hand" :items="[0, 1,2]" label="valeur"
                          readonly />
                      </v-col>
                    </v-row>
                    <v-row dense>

                      <v-col cols="12" md="4">
                        <v-select name="pied" v-model="athlete.lat_foot"
                          :items="[{ title: 'pied droit', value: 1 }, { title: 'pied gauche', value: 0 }, { title: 'les deux', value: 2 }]" label="" />
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-select name="valeur pied" v-model="athlete.lat_foot" :items="[0, 1,2]" label="valeur"
                          readonly />
                      </v-col>
                    </v-row>
                    <v-row dense>

                      <v-col cols="12" md="4">
                        <v-select label="genre" name="genre" v-model="athlete.sex" :items="['M', 'F']" />
                      </v-col>
                    </v-row>
                    <v-row> <v-col cols="6" md="4">

                        <v-date-picker id="date_athlete" label="date" title="Date de naissance" v-model="selected_date"
                          name="date" width="400" color="primary" />

                      </v-col>
                    </v-row>
                    <v-btn type="submit" class="btn btn-primary" @click="isActive.value = false">mettre à jour</v-btn>
                  </v-card>
                </v-form>
              </template>
            </v-dialog>
          <!--<li><router-link :to="{name: 'athlete', params:{id: athlete.idAthlete}}">View</router-link></li>-->
            <v-btn @click="this.suivi(athlete)"><svg-icon type="mdi" :path="path_heart"></svg-icon></v-btn>
            <v-btn @click="this.delete(athlete)"><svg-icon type="mdi" :path="path_delete"></svg-icon></v-btn>  </td>
        </tr>
      </v-table>
      <div v-if="athletes.length">




      </div>
      <div v-else>
        <p>Nothing to see. Check back later.</p>
      </div>
    </section>
  </div>
</template>
<script>
//  import athletes from '@/store/modules/athletes';
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import '@/css/forms.css';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiHumanEdit } from '@mdi/js';
import { mdiAccountPlusOutline } from '@mdi/js';
import { mdiDeleteForever } from '@mdi/js';
import { mdiHeartPulse } from '@mdi/js';
DataTable.use(DataTablesCore);
export default defineComponent({
  name: 'Dashboard-cep',
  components: {
    SvgIcon
  },
  data() {
    return {
      form: {
        title: 'ajouter un athlete',
        nom: '',
        prenom: '',
        date_naissance: '',
        main_droitier: '',
        pied_droitier: ''
      },
      form_search: {
        needle: ''
      },
      selected_date: new Date(),
      new_date: new Date(),
      path_edit: mdiHumanEdit,
      path_add: mdiAccountPlusOutline,
      path_delete:mdiDeleteForever ,
      path_heart: mdiHeartPulse,
      

    };
  },
  created: function () {
    this.redirect();
    return this.$store.dispatch('getAthletes');
  },
  updated:function(){
    let tet=this.$store.getters.isTokenExpire;
    if(tet){
      this.$router.push("/login");
    }
  },
  computed: {
    ...mapGetters({ athletes: 'getAthletes' }),
    ...mapGetters({ selected: 'selected' }),
    ...mapGetters({ new_athlete: 'new_athlete' }),
  
  },
  methods: {
    redirect:function(){
        if(this.$store.getters.isTokenExpire){
          this.$router.push('/login');

        }
    
    },
    suivi(athlete){
        this.selectAthlete(athlete.idAthlete);
        this.$router.push("/suivi");
    },
    ...mapActions(['addAthlete']),
    async submit() {
      this.redirect();
      let date_selected = this.new_date.toISOString();
      this.new_athlete.birthdate = date_selected;
      await this.addAthlete();
    },
    
    ...mapActions(['updateAthlete']),
    async update() {
      this.redirect();
      let date_selected = this.selected_date.toISOString();
      this.selected.birthdate = date_selected;
      await this.updateAthlete(this.selected);
    },
    ...mapActions(['deleteAthlete']),
    async delete(athlete) {
      this.redirect();
      await this.deleteAthlete(athlete);
    },

    ...mapActions(['selectAthlete']),
    select(idAthlete) {
      this.selectAthlete(idAthlete);
      this.selected_date = new Date(this.selected.birthdate);
    },
    ...mapActions(['searchAthletes']),
    async search() {
      await this.searchAthletes(this.form_search.needle)
    }
  },
});
</script>