// fichier : suivis. js 
// 
//  description : modules store vuex,  pour regrouper des traitement sur les états de formes,
//                 les blessures, les pathology
// 
//  TODO : sortir les traitemens sur les composition athlètes vers users.js
// 
// import axios from 'axios';
import * as client from './client/index';
const key_AthCompo="AthSuiviCompo";

const state = {
    current_state_of_form: {},
    current_evaluation: {},  //last evalation read from server or input from UI Form
    current_injury: {},     // last injury read from server or input from UI Form
    current_pathology: {},  // last pathology read from server or input from UI Form
    current_pain:{},        // last pain read from server or input from UI Form
    last_eval: {},          // last evaluation read from server (not used )
    
    localisations:[],// localisations for select input in any UI forms  
    
    //dashboard trainer
    athletes_composition: [], // list of athlete compositions
    athletes_teams:[],        // list of athlete teams 
    evaluations: [], //evaluations for all athletes see also in users.js stateFormAthlete which store the evaluations for the athlete's dashboard
    
    // dashboard athlete
    states_of_from: [], // state of form for each needed in UI
    injuries: [],// injuries for each component needed in UI
    pains:[],//pain for each component  needed in UI
    pathologies: [],//pathology for each component needed in UI
    selected_evaluation: {}, 
    selected_etat_de_forme: {},
    history_eval: {},
};

const getters = {
    athlete_suivi_compo: state => state.athletes_composition,
    athletes_suivi_teams: state => state.athletes_teams,
    
    current_state_of_form: state => state.current_state_of_form,
    current_evaluation: state => state.current_evaluation,
    current_injury: state => {
        if(state.current_injury)var date=new Date(state.current_injury.date);
        var hook=JSON.parse(JSON.stringify(state.current_injury));
        hook.date=date.toLocaleDateString("fr");
        return hook;
    },
    current_injury_raw:state => state.current_injury,
    current_pathology: state => state.current_pathology,
    current_pain:state => state.current_pain,    
    evaluations: state => state.evaluations,
    etatsQuoti: state => {
        state.state_of_form.filter(elem=>elem.isDaily == 1);
    },
    etats_de_formes: state => state.states_of_from,
    injuries: state => state.injuries,
    injury_by_id: (state) => (id) =>{

       return state.injuries.filter((injury)=>injury!=undefined).find(injury => injury.idInjury === id);
 
    },
    last_eval: state => state.last_eval,
    localisations:state =>{
        //copy to avoid trigger recursive state (if any )  
        var hook=JSON.parse(JSON.stringify(state.localisations));
       // var hook=state.localisations
        var list=[];
        var loc=[" : membre supérieure"," : membre inférieure"]
        hook.forEach(element => {
            let tmp={};
            if(element.DOMSup == 1){
                tmp.title=String(element.description)+loc[0];
            }
            else{
                tmp.title=String(element.description)+loc[1];
            }
            tmp.value=element.idLocalisation
            list.push(tmp);
        });
        return list;
    },
    localisationsForPain: (state)=>  (id) => {
        //first we need the proper pain element
        var pain=null;
        var tmp={};
        state.pains.forEach(element =>{if(element.idPain==id)pain=element;});
        
        // then we send an object readable for a select ie with a title and a value 
        var loc=[" : membre supérieure"," : membre inférieure"]
        state.localisations.forEach(element=>{
            if(element != undefined && element != "empty"){
                if(element.idLocalisation==pain.pain_idLocalisation){
                    
                    if(element.DOMSup == 1){
                        tmp.title=String(element.description)+loc[0];
                    }
                    else{
                        tmp.title=String(element.description)+loc[1];
                    }
                    tmp.value=element.idLocalisation;
                    return;
                }
            }
        });
        return tmp;
    },
    localisationsForInjury:(state)=>(id) => {
        //var injury=null;
        
        //state.injuries.forEach(element =>{if(element.idInjury==id)injury=element;});
        var tmp={}
        var injury=state.injuries.filter((element)=>element!=undefined).find((element)=>element.idInjury==id);
        var loc=[" : membre supérieure"," : membre inférieure"]
        var localisation=state.localisations.filter((element)=> (element != undefined)&& (element != "empty")).find((element)=>(element.idLocalisation==injury.injury_idLocalisation));
        if(localisation.DOMSup == 1){
            tmp.title=String(localisation.description)+loc[0];
            }
        else{
            tmp.title=String(localisation.description)+loc[1];
        }
        tmp.value=localisation.idLocalisation;
        return tmp;
        // state.localisations.forEach(element=>{
        //     if(element != undefined && element != "empty"){
        //         if(element.idLocalisation==injury.injury_idLocalisation){
                    
        //             if(element.DOMSup == 1){
        //                 tmp.title=String(element.description)+loc[0];
        //             }
        //             else{
        //                 tmp.title=String(element.description)+loc[1];
        //             }
        //             tmp.value=element.idLocalisation;
        //             return;
        //         }
        //     }
        // });
        // return tmp;
    },
    pains: state => {
        return JSON.parse(JSON.stringify(state.pains));
    },
    pathologies: state =>{
        return JSON.parse(JSON.stringify(state.pathologies));
    } ,
    selected_etat_de_forme: state => state.selected_etat_de_forme,
    selected_eval: state => state.history_eval,
    selected_evaluation: state => state.selected_evaluation,

};
const actions = {
    /**
     * 1  Dashoard trainer /admin context 
     *  
     */
    async getComposition({ commit, dispatch }) {
        var states_ids = [];
        if(this.getters.selected?.idAthlete){
            states_ids=[this.getters.selected?.idAthlete]

        }
        else{
            states_ids=[JSON.parse(localStorage.getItem(this.getters.key_sel_athl)).idAthlete]
        }
        
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result = await client.readCompositionByAthleteIds(token, states_ids);
            commit("setCompositions", result.result);
            dispatch("getEquipes");
        }
    },   
    async getEquipes({ commit , dispatch }) {
        var team_ids = [];
        var teams = this.getters.athlete_suivi_compo;
        if (this.getters.athlete_suivi_compo[0] == null ){
            teams=JSON.parse(localStorage.getItem(key_AthCompo));
        }
        for (const item of teams) {
            team_ids.push(item.composition_idTeam);
        }
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result = await client.readTeamsByIds(token, team_ids);
            commit("setTeams", result.result);
            dispatch("getStatesOfForm");
        }
    },
 
    async getStatesOfForm({ commit }) {
        var sel_athlete = this.getters.selected;
        if(sel_athlete.idAthlete == null){
            sel_athlete=JSON.parse(localStorage.getItem(this.getters.key_sel_athl));
        }
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var fetch = await client.readStateFormAthletes(token, sel_athlete);
            commit('setStateOfForm', fetch.result);
            //dispatch('getInjuries');
        }

    },
    
    getSelectedAthlete(){
        //
        // TODO : on ne devrait pas partager des clés sur un local storge entre les stores...
        //
        var selected=this.getters.selected
        if(selected.idAthlete != null ){
            return selected;
        }
        else{
            selected=JSON.parse(localStorage.getItem(this.getters.key_sel_athl));
            return selected;
        }
    },
/***************************************************************************************** */
    /*
    *  Dashboard athlete 
    *    
    * 
    **/
/******************************************************************************************* */
    async getLocalisations({commit}){
        /**
         *  Getloclaisation list 
         */
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result=await client.readLocalisationByIds(token,[]);
            commit("setLocalisations",result.result)
        }
    },
    async clean_current_suivi({commit}){
        // clean current_**
        await commit("setStateOfForm",{});
        await commit("setInjury", {});
        await commit("setPathology", {});
        await commit("setPain",{}); 
    },

  
    async  setCurrentStateOfForm({commit},forms){
        /**
         *  setCurrentStateOfForm : set a state of form in the vuex store 
         */
        // forms should contains proper (key,value) 
        commit("setStateOfForm",forms);
    },
    async createStateOfForm({commit}){
        /**
        *createStateOfForm :
        *     first : save the state of form in case of daily evaluation and get the idStateOfForm
        *    second : save  a current_injury and/or a current _pathology and or a current_pain with the idStateOfForm
        *
        */
        
        var result=null;
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            result = await client.createStateForm(token,this.getters.current_state_of_form);
            var idStateOfForm=result.result.idStateOfForm;
            if(this.getters.current_injury_raw!= undefined && Object.keys(this.getters.current_injury_raw).length!=0 ){
                //we should have the localisation FK allready in the injury_localisation field.
                this.getters.current_injury_raw.injury_idStateOfForm=idStateOfForm;
                await client.createInjury(token,this.getters.current_injury_raw);
                
            }
            if(this.getters.current_pathology!=undefined && Object.keys(this.getters.current_pathology).length!=0){
                this.getters.current_pathology.pathology_idStateOfForm=idStateOfForm;
                await client.createPathology(token,this.getters.current_pathology);

            }
            if(this.getters.current_pain!=undefined && Object.keys(this.getters.current_pain).length!=0){
                this.getters.current_pain.pain_idStateOfForm=idStateOfForm;
                await client.createPain(token,this.getters.current_pain);
            }
            

        }
        commit("setInjury",{});
        commit("setPathology",{});
        commit("setStateOfForm",{});
        return result;
    },
    /*
    *
    *   modale Injury 
    *       add or display an injury in view
    *
    */ 
    async setInjury({commit},injury){
        /**
        action to save an injury from view
         *  
         */
        commit("setInjury",injury);
    },
    async getInjuryByIds({commit},ids=[]){
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if(token){
            const result= await client.readInjuryByIds(token,ids);
            await commit("setInjury",result.result[0]);
            await commit("addInjury",result.result[0]);
        }

    },
  
    
    /*
    *
    *   modale pathology
    *
    */

    async getPathology({commit},ids=[]){
        // we supposely have one pathology by modale
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result=await client.readPathologyByIds(token,ids);
            await commit("setPathology",result.result[0]);
            await commit("addPathology",result.result[0]);
        }
    },
    async setActionPathology({commit},pathology){

        commit("setPathology",pathology);
    },
    /*
        modale pain
    */
    async getPain({commit},ids=[]){
        // we supposely have one pain by modale here 
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result=await client.readPainByIds(token,ids);
            await commit("setPain",result.result[0]);
            await commit("addPain",result.result[0]);
        }
    },

    async setActionPain({commit},pain){
        commit("setPain",pain);
    },

    
};

const mutations = {
    setPain(state,pain){
        state.current_pain=pain;

    },
    addPain(state,pain){
        state.pains.push(pain);
    },
    setStateOfForm(state, state_of_form) {
        state.current_state_of_form = state_of_form;
        

    },
    addStateOfForm(state, state_of_form) {
        state.current_state_of_form.push(state_of_form);

    },
    setEvaluation(state, evaluations) {
        state.evaluations = evaluations;
    },
    setLocalisations(state,localisations){
        state.localisations=localisations;
    },
    // setInjuries(state, injuries) {
    //     state.injuries = injuries;
    // },
    addInjury(state, injury) {
        state.injuries.push(injury);
    },
    setInjury(state,injury){
        state.current_injury=injury;
    },
    setCompositions(state, compos) {
        state.athletes_composition = compos;
        localStorage.setItem(key_AthCompo,JSON.stringify(compos))
    },
    addPathology(state,pathology){
        state.pathologies.push(pathology);
    },
    setPathology(state,pathology){
        state.current_pathology=pathology;
    },
    setTeams(state,teams){
        state.athletes_teams=teams;

    }

 

};

export default {
    state,
    getters,
    actions,
    mutations
};